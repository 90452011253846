.wallet-summary{
    font-size: 13px;
    margin-top: 8px;
}

.wallet-top-up {
    margin-top: 20px;
}

/* --------------------------------------------------------------------- */
.wizard {
    margin: 20px auto;
    background: #fff;
}

.wizard .nav-tabs {
    position: relative;
    margin: 40px auto;
    margin-bottom: 0;
    border-bottom-color: #e0e0e0;
}

.wizard > div.wizard-inner {
    position: relative;
}

.connecting-line {
    height: 2px;
    background: #e0e0e0;
    position: absolute;
    width: 80%;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 50%;
    z-index: 1;
}

.wizard .nav-tabs > li.active > a, .wizard .nav-tabs > li.active > a:hover, .wizard .nav-tabs > li.active > a:focus {
    color: #555555;
    cursor: default;
    border: 0;
    border-bottom-color: transparent;
}

span.round-tab {
    width: 70px;
    height: 70px;
    line-height: 70px;
    display: inline-block;
    border-radius: 100px;
    background: #fff;
    border: 2px solid #e0e0e0;
    z-index: 2;
    position: absolute;
    left: 0;
    text-align: center;
    font-size: 25px;
}
span.round-tab i{
    color:#555555;
}
.wizard li.active span.round-tab {
    background: #fff;
    border: 2px solid #5bc0de;
    
}
.wizard li.active span.round-tab i{
    color: #5bc0de;
}

span.round-tab:hover {
    color: #333;
    border: 2px solid #333;
}

.wizard .nav-tabs > li {
    width: 25%;
}

.wizard li:after {
    content: " ";
    position: absolute;
    left: 46%;
    opacity: 0;
    margin: 0 auto;
    bottom: 0px;
    border: 5px solid transparent;
    border-bottom-color: #5bc0de;
    transition: 0.1s ease-in-out;
}

.wizard li.active:after {
    content: " ";
    position: absolute;
    left: 46%;
    opacity: 1;
    margin: 0 auto;
    bottom: 0px;
    border: 10px solid transparent;
    border-bottom-color: #5bc0de;
}

.wizard .nav-tabs > li a {
    width: 70px;
    height: 70px;
    margin: 20px auto;
    border-radius: 100%;
    padding: 0;
}

    .wizard .nav-tabs > li a:hover {
        background: transparent;
    }

.wizard .tab-pane {
    position: relative;
    padding-top: 50px;
}

.wizard h3 {
    margin-top: 0;
}

@media( max-width : 585px ) {

    .wizard {
        width: 90%;
        height: auto !important;
    }

    span.round-tab {
        font-size: 16px;
        width: 50px;
        height: 50px;
        line-height: 50px;
    }

    .wizard .nav-tabs > li a {
        width: 50px;
        height: 50px;
        line-height: 50px;
    }

    .wizard li.active:after {
        content: " ";
        position: absolute;
        left: 35%;
    }
}

.step {
    list-style: none;
    margin: .2rem 0;
    width: 100%;
  }
  
  .step .step-item {
    -ms-flex: 1 1 0;
    flex: 1 1 0;
    margin-top: 0;
    min-height: 1rem;
    position: relative; 
    text-align: center;
  }
  
  .step .step-item:not(:first-child)::before {
    /* background: #4c4f52; */
    content: "";
    height: 2px;
    left: -50%;
    position: absolute;
    top: 7px;
    width: 100%;
  }
  
  .step .step-item a {
    color: #636568;
    display: inline-block;
    padding: 20px 10px 0;
    text-decoration: none;
  }
  
  .step .step-item a::before {
    /* background: #636568; */
    border: .1rem solid #fff;
    border-radius: 50%;
    content: "";
    display: block;
    height: .9rem;
    left: 50%;
    position: absolute;
    top: .2rem;
    transform: translateX(-50%);
    width: .9rem;
    z-index: 1;
  }
  
  .step .step-item.active a::before {
    background: #fff;
    border: .1rem solid #636568;
  }
  
  .step .step-item.active ~ .step-item::before {
    background: #e7e9ed;
  }
  
  .step .step-item.active ~ .step-item a::before {
    background: #e7e9ed;
  }



  .credit-default, .credit-make-default {
    display: inline-block;
    width: 25px;
    height: 25px;
    text-align: center;
    padding: 2px 0;
    position: absolute;
    top: -6px;
    right: -9px;
}

.credit-make-default {
    border-radius: 50%;
    color: #333;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(0, 0, 0);
    cursor: pointer;
}

.credit-default {
    border-radius: 50%;
    color: rgb(255, 255, 255);
    background-color: rgb(34, 175, 9);
    border: 1px solid rgb(0, 180, 30);
    cursor: pointer;
}

.react-confirm-alert-overlay {
    background-color: rgb(255, 255, 255, 0.5);
}

